import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useAccount, useSendTransaction } from "wagmi";
import { useConnectModal } from "@rainbow-me/rainbowkit";
import { campaignWallet } from "configs/constant";
import { parseEther } from "viem";
import Marquee from "react-fast-marquee";
import { add } from "date-fns";
import axios from "axios";
import {
  Box,
  Paper,
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { purchasePackage } from "services/api.slice";
import { getDateTimeDifference } from "Utils";
import CountDownBoard from "components/CountDownBoard/CountDownBoard";
import { images } from "./items";

const Campaign = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation();

  const user = useSelector((state) => state.auth.user);
  const { enqueueSnackbar } = useSnackbar();
  const { openConnectModal } = useConnectModal();
  const { isConnected } = useAccount();
  const { data, sendTransaction } = useSendTransaction();

  const [currentUser, setCurrentUser] = useState();
  const [currentPrice, setPrice] = useState("10");
  const [currentCoinPrice, setCurrentPrice] = useState();
  const currentRealTime = Math.floor(new Date().getTime() / 1000);

  const date = new Date(Date.UTC(2024, 12, 26));
  const dateDiff = getDateTimeDifference(
    date.getTime() - 1000,
    new Date().getTime()
  );

  const futureDate = add(new Date(), {
    days: date.getTime() - 1000 > currentRealTime ? dateDiff?.days : 0,
    hours: date.getTime() - 1000 > currentRealTime ? dateDiff?.hours : 0,
    minutes: date.getTime() - 1000 > currentRealTime ? dateDiff?.minutes : 0,
    seconds: date.getTime() - 1000 > currentRealTime ? dateDiff?.seconds : 0,
  });

  useEffect(() => {
    if (user) {
      setCurrentUser(user);
      fetchPrices();
    }
  }, [user]);

  const fetchPrices = async () => {
    try {
      const response = await axios.get(
        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum,binancecoin,matic-network&vs_currencies=usd"
      );
      setCurrentPrice(response?.data?.[`matic-network`]?.usd);
    } catch (error) {
      console.error("Error fetching prices:", error);
    }
  };

  useEffect(() => {
    if (data) {
      handlePurchaseAPI();
    }
  }, [data]);

  const handlePurchaseAPI = async () => {
    const purchaseData = {
      userEmail: currentUser.email,
      packageType: currentPrice.toString(),
    };
    const result = await purchasePackage(purchaseData);

    if (result) {
      showSnackMessage("You Purchased Package", "success");
    } else {
      showSnackMessage("Failed to purchase", "error");
    }
  };

  const showSnackMessage = (msg, variant) => {
    enqueueSnackbar(msg, { variant });
  };

  const handlePurchase = async (price) => {
    if (currentUser.email) {
      if (!isConnected) {
        showSnackMessage(t("NEED_WALLET"), "error");
        openConnectModal();
        return;
      }
      sendTransaction({
        to: campaignWallet,
        value: parseEther((Number(price) / currentCoinPrice).toString()),
      });

      setPrice(price);
    } else {
      showSnackMessage(t("MUST_LOGIN"), "error");
    }
  };

  return (
    <Box
      sx={{
        background: "linear-gradient(rgb(43, 23, 23) 0%, rgb(25, 25, 25) 100%)",
      }}
      pb={4}
    >
      <Box
        sx={{
          backgroundImage:
            "url('https://abroadad.cache.wpscdn.com/upload/ad_adapter/2024-11-11/d35e90b60c7180b9ce7a86b7fa75b4ea.png')",
          display: "flex",
          alignItems: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          width: "100%",
          paddingBottom: "9px",
          backgroundPosition: "center",
          height: `${isMobile ? "200px" : "400px"}`,
          flexDirection: "column",
        }}
      >
        <Box mx="auto" mt="auto">
          <Typography
            variant="h4"
            fontWeight="bold"
            textAlign="center"
            mb={4}
            sx={{ color: "white" }}
          >
            👉Big NFT SALE! Last Chance in this year!!!👉
          </Typography>
        </Box>
        <Box sx={{ width: "50%" }}>
          <CountDownBoard
            darkMode={true}
            futureDate={futureDate}
            isCard={true}
          />
        </Box>
      </Box>
      <Box
        sx={{
          background: "#191919",
          border: "1px solid rgba(240, 137, 103, 0.8)",
          boxShadow: "rgba(255, 0, 0, 0.4) 0px 0px 48px 0px inset",
          borderRadius: "24px",
          padding: "30px",
          color: "#fff",
        }}
        mt={-1}
        mx={2}
        mb={3}
      >
        <Typography variant="h5" fontWeight="bold" textAlign="center" mb={4}>
          🛍️ NFT Bonanza – Limited-Time Deals! 🛍️
        </Typography>
        <Typography>
          🔥 Get ready to own exclusive NFTs at unbeatable prices! This Year
          LAST CHANCE, we’re bringing you amazing NFT packages designed for
          everyone – from enthusiasts to professionals.
        </Typography>
        <br />
        <Box textAlign="center">
          <a
            href="https://t.me/+2_n4LiSboIcyOGIx"
            target="_blank"
            style={{ fontSize: "20px", textAlign: "center" }}
          >
            <u>👉Join Our Community</u>
          </a>
        </Box>
      </Box>
      <Box
        sx={{
          background: "#191919",
          border: "1px solid rgba(240, 137, 103, 0.8)",
          boxShadow: "rgba(255, 0, 0, 0.4) 0px 0px 48px 0px inset",
          borderRadius: "24px",
          padding: "20px 20px 32px",
        }}
        mt={-1}
        mx={2}
        mb={3}
      >
        <Box
          sx={{
            backgroundImage:
              "linear-gradient(rgb(111, 85, 85) 13.5%, rgb(206, 206, 206) 50%, rgb(168, 131, 131) 100%)",
            boxShadow: "rgba(0, 0, 0, 0.5) 0px 1px 4px 0px",
            left: "44px",
            width: "16px",
            height: "72px",
            borderRadius: "28px",
            position: "absolute",
            zIndex: 10,
            marginTop: "-64px",
          }}
        />
        <Box
          sx={{
            backgroundImage:
              "linear-gradient(rgb(111, 85, 85) 13.5%, rgb(206, 206, 206) 50%, rgb(168, 131, 131) 100%)",
            boxShadow: "rgba(0, 0, 0, 0.5) 0px 1px 4px 0px",
            right: "44px",
            width: "16px",
            height: "72px",
            borderRadius: "28px",
            position: "absolute",
            zIndex: 10,
            marginTop: "-64px",
          }}
        />
        <Box>
          <Typography
            color="#fff"
            mb={2}
            textAlign="center"
            fontSize="26px"
            fontWeight="bold"
          >
            Exclusive NFT Packages
          </Typography>
          <Marquee direction="left">
            <Box
              display="flex"
              flexDirection="row"
              gap={2}
              mb={3}
              alignItems="flex-start"
              style={{
                "& .rfm-marquee": {
                  minWidth: "unset",
                },
              }}
            >
              {images.map((v, i) => (
                <Box key={i}>
                  <img
                    src={v.image}
                    alt="1"
                    width="120px"
                    style={{
                      borderRadius: "20px",
                      marginLeft: i === 0 && "16px",
                    }}
                  />
                </Box>
              ))}
            </Box>
          </Marquee>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={12}>
              <Paper
                sx={{
                  borderRadius: "24px",
                  padding: "40px",
                  backgroundImage:
                    "radial-gradient(100% 136.01% at 0% 0%, rgb(61, 12, 12) 0%, rgb(11, 11, 11) 40%)",
                  border: "1px solid rgba(255, 64, 63, 0.2)",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                <Typography>🚀 10 NFTs</Typography>
                <Typography mb={2}>🎁 Bonus: 120 bonus bids</Typography>
                <Typography fontSize="20px" fontWeight="bold" mb={2}>
                  <span style={{ fontSize: "40px" }}>
                    $90{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      {" "}
                      $100
                    </span>
                  </span>
                  /Package
                </Typography>
                <Button
                  sx={{
                    backgroundImage:
                      "linear-gradient(273.12deg, rgb(137, 78, 248) -1.17%, rgb(37, 103, 255) 47.7%, rgb(69, 197, 255) 103.54%)",
                    border: "1px solid rgb(58, 172, 255)",
                    boxShadow:
                      "rgba(0, 64, 255, 0.3) 0px 9px 8.2px 0px, rgba(76, 252, 255, 0.2) 0px 6px 4px 0px inset, rgba(76, 252, 255, 0.2) 0px -6px 4px 0px inset, rgba(76, 252, 255, 0.2) 6px 0px 4px 0px inset, rgba(94, 76, 255, 0.2) -6px 0px 4px 0px inset",
                    borderRadius: "40px",
                    padding: "14px 20px",
                  }}
                  onClick={() => handlePurchase("90")}
                >
                  Purchase Now
                </Button>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Paper
                sx={{
                  borderRadius: "24px",
                  padding: "40px",
                  backgroundImage:
                    "radial-gradient(100% 136.01% at 0% 0%, rgb(61, 12, 12) 0%, rgb(11, 11, 11) 40%)",
                  border: "1px solid rgba(255, 64, 63, 0.2)",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                <Typography>🚀 30 NFTs</Typography>
                <Typography mb={2}>🎁 Bonus: 250 bonus bids</Typography>
                <Typography fontSize="20px" fontWeight="bold" mb={2}>
                  <span style={{ fontSize: "40px" }}>
                    $180{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      {" "}
                      $200
                    </span>
                  </span>
                  /Package
                </Typography>
                <Button
                  sx={{
                    backgroundImage:
                      "linear-gradient(273.12deg, rgb(137, 78, 248) -1.17%, rgb(37, 103, 255) 47.7%, rgb(69, 197, 255) 103.54%)",
                    border: "1px solid rgb(58, 172, 255)",
                    boxShadow:
                      "rgba(0, 64, 255, 0.3) 0px 9px 8.2px 0px, rgba(76, 252, 255, 0.2) 0px 6px 4px 0px inset, rgba(76, 252, 255, 0.2) 0px -6px 4px 0px inset, rgba(76, 252, 255, 0.2) 6px 0px 4px 0px inset, rgba(94, 76, 255, 0.2) -6px 0px 4px 0px inset",
                    borderRadius: "40px",
                    padding: "14px 20px",
                  }}
                  onClick={() => handlePurchase("180")}
                >
                  Purchase Now
                </Button>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Paper
                sx={{
                  borderRadius: "24px",
                  padding: "40px",
                  backgroundImage:
                    "radial-gradient(100% 136.01% at 0% 0%, rgb(61, 12, 12) 0%, rgb(11, 11, 11) 40%)",
                  border: "1px solid rgba(255, 64, 63, 0.2)",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                <Typography>🚀 100 NFTs</Typography>
                <Typography mb={2}>🎁 Bonus: 800 bonus bids</Typography>
                <Typography fontSize="20px" fontWeight="bold" mb={2}>
                  <span style={{ fontSize: "40px" }}>
                    $425{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      {" "}
                      $500
                    </span>
                  </span>
                  /Package
                </Typography>
                <Button
                  sx={{
                    backgroundImage:
                      "linear-gradient(273.12deg, rgb(137, 78, 248) -1.17%, rgb(37, 103, 255) 47.7%, rgb(69, 197, 255) 103.54%)",
                    border: "1px solid rgb(58, 172, 255)",
                    boxShadow:
                      "rgba(0, 64, 255, 0.3) 0px 9px 8.2px 0px, rgba(76, 252, 255, 0.2) 0px 6px 4px 0px inset, rgba(76, 252, 255, 0.2) 0px -6px 4px 0px inset, rgba(76, 252, 255, 0.2) 6px 0px 4px 0px inset, rgba(94, 76, 255, 0.2) -6px 0px 4px 0px inset",
                    borderRadius: "40px",
                    padding: "14px 20px",
                  }}
                  onClick={() => handlePurchase("425")}
                >
                  Purchase Now
                </Button>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Paper
                sx={{
                  borderRadius: "24px",
                  padding: "40px",
                  backgroundImage:
                    "radial-gradient(100% 136.01% at 0% 0%, rgb(61, 12, 12) 0%, rgb(11, 11, 11) 40%)",
                  border: "1px solid rgba(255, 64, 63, 0.2)",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                <Typography>🚀 300 NFTs</Typography>
                <Typography mb={2}>🎁 Bonus: 2000 bonus bids</Typography>
                <Typography fontSize="20px" fontWeight="bold" mb={2}>
                  <span style={{ fontSize: "40px" }}>
                    $850{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      {" "}
                      $1000
                    </span>
                  </span>
                  /Package
                </Typography>
                <Button
                  sx={{
                    backgroundImage:
                      "linear-gradient(273.12deg, rgb(137, 78, 248) -1.17%, rgb(37, 103, 255) 47.7%, rgb(69, 197, 255) 103.54%)",
                    border: "1px solid rgb(58, 172, 255)",
                    boxShadow:
                      "rgba(0, 64, 255, 0.3) 0px 9px 8.2px 0px, rgba(76, 252, 255, 0.2) 0px 6px 4px 0px inset, rgba(76, 252, 255, 0.2) 0px -6px 4px 0px inset, rgba(76, 252, 255, 0.2) 6px 0px 4px 0px inset, rgba(94, 76, 255, 0.2) -6px 0px 4px 0px inset",
                    borderRadius: "40px",
                    padding: "14px 20px",
                  }}
                  onClick={() => handlePurchase("850")}
                >
                  Purchase Now
                </Button>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Paper
                sx={{
                  borderRadius: "24px",
                  padding: "40px",
                  backgroundImage:
                    "radial-gradient(100% 136.01% at 0% 0%, rgb(61, 12, 12) 0%, rgb(11, 11, 11) 40%)",
                  border: "1px solid rgba(255, 64, 63, 0.2)",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                <Typography>🚀 1500 NFTs</Typography>
                <Typography mb={2}>🎁 Bonus: 10000 bonus bids</Typography>
                <Typography fontSize="20px" fontWeight="bold" mb={5}>
                  <span style={{ fontSize: "40px" }}>
                    $4000{" "}
                    <span style={{ textDecoration: "line-through" }}>
                      {" "}
                      $5000
                    </span>
                  </span>
                  /Package
                </Typography>
                <Button
                  sx={{
                    backgroundImage:
                      "linear-gradient(273.12deg, rgb(137, 78, 248) -1.17%, rgb(37, 103, 255) 47.7%, rgb(69, 197, 255) 103.54%)",
                    border: "1px solid rgb(58, 172, 255)",
                    boxShadow:
                      "rgba(0, 64, 255, 0.3) 0px 9px 8.2px 0px, rgba(76, 252, 255, 0.2) 0px 6px 4px 0px inset, rgba(76, 252, 255, 0.2) 0px -6px 4px 0px inset, rgba(76, 252, 255, 0.2) 6px 0px 4px 0px inset, rgba(94, 76, 255, 0.2) -6px 0px 4px 0px inset",
                    borderRadius: "40px",
                    padding: "14px 20px",
                  }}
                  onClick={() => handlePurchase("4000")}
                >
                  Purchase Now
                </Button>
              </Paper>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Paper
                sx={{
                  borderRadius: "24px",
                  padding: "40px",
                  backgroundImage:
                    "radial-gradient(100% 136.01% at 0% 0%, rgb(61, 12, 12) 0%, rgb(11, 11, 11) 40%)",
                  border: "1px solid rgba(255, 64, 63, 0.2)",
                  color: "#fff",
                  textAlign: "center",
                }}
              >
                <Typography>🚀 3000 NFTs</Typography>
                <Typography mb={2}>🎁 Bonus: 25000 bonus bids</Typography>
                <Typography fontSize="20px" fontWeight="bold" mb={2}>
                  <span style={{ fontSize: "40px" }}>
                    $8000
                    <span style={{ textDecoration: "line-through" }}>
                      {" "}
                      $10000
                    </span>
                  </span>
                  /Package
                </Typography>
                <Button
                  sx={{
                    backgroundImage:
                      "linear-gradient(273.12deg, rgb(137, 78, 248) -1.17%, rgb(37, 103, 255) 47.7%, rgb(69, 197, 255) 103.54%)",
                    border: "1px solid rgb(58, 172, 255)",
                    boxShadow:
                      "rgba(0, 64, 255, 0.3) 0px 9px 8.2px 0px, rgba(76, 252, 255, 0.2) 0px 6px 4px 0px inset, rgba(76, 252, 255, 0.2) 0px -6px 4px 0px inset, rgba(76, 252, 255, 0.2) 6px 0px 4px 0px inset, rgba(94, 76, 255, 0.2) -6px 0px 4px 0px inset",
                    borderRadius: "40px",
                    padding: "14px 20px",
                  }}
                  onClick={() => handlePurchase("8000")}
                >
                  Purchase Now
                </Button>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box mx={4} textAlign="center">
        <Typography color="#fff" fontSize="20px">
          🌟 Please Note: All NFTs and bonus bids will be delivered in 24 ~ 48
          hours once you purchase. Thank you for your patience and enthusiasm as
          we bring these exclusive deals to life! 🎁✨
        </Typography>
      </Box>
    </Box>
  );
};

export default Campaign;
